@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;600;700&display=swap');


.logo-title {
    font-family: 'Cinzel', serif;
  font-size: 64px;
  font-weight: bold;
  color: #d3b685;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.logo-title-fr {
  font-family: 'Cinzel', serif;
font-size: 64px;
font-weight: bold;
color: #d3b685;
margin: 0;
margin-top: -10px;
padding: 0;
text-align: center;
text-transform: uppercase;
letter-spacing: 4px;
}

.logo-title-small {
  font-family: 'Cinzel', serif;
font-size: 42px;
font-weight: bold;
color: #d3b685;
margin: 0;
padding: 0;
text-align: center;
text-transform: uppercase;
letter-spacing: 4px;
}

.logo-subtitle {
    font-family: 'Nunito', sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: #d3b685;
    margin: 0;
    margin-top: -10px;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.logo-subtitle-fr {
  font-family: 'Nunito', sans-serif;

  font-size: 32px;
  font-weight: bold;
  color: #d3b685;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 10px;
}

  .logo-subtitle-small {
    font-family: 'Nunito', sans-serif;
    

    font-size: 18px;
    font-weight: bold;
    color: #d3b685;
    margin: 0;
    margin-top: -10px;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .description{
    color:#999999;
    font-family: 'Nunito', sans-serif;
    text-align: center;
    
    letter-spacing: 2px;
  }

  .big-logo-box{
    width:600px;
    max-width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.big-logo-box .logo{
  width:300px;
  max-width: 100%;
  margin: auto;
}
