.user-menu {
  position: fixed;
  top: 0;
  right: -340px; /* Hidden position */
  height: 100vh;
  width: 300px;
  padding: 20px;
  background-color: #333333;
  color: #d3b685;
  transition: right 0.3s ease-in-out;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  overflow-y: auto; /* Allow scrolling if content overflows */
}

.user-menu.show {
  right: 0; /* Visible position */
}

.user-menu.hide {
  right: -340px; /* Hidden position */
}

.menu-title {
  padding: 10px;
  text-align: center;
  font-size: 26px;
  letter-spacing: 1px;
  color: #d3b685;
  font-weight: bold;
}

.user-login {
  border: 1px solid #d3b685;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}

.user-login div {
  margin-bottom: 10px;
}



.user-login-button {
  margin-top: 10px !important;
  background-color: #d3b685 !important;
  color: #333333 !important;
  width: 100%;
}

.user-login-button:hover {
  background-color: #c5a367 !important;
}
.user-login-input {
  margin-bottom: 16px;
}

.user-login-input .MuiOutlinedInput-root {
  color: #d3b685;
}

.user-login-input .MuiOutlinedInput-root fieldset {
  border-color: #d3b685;
}

.user-login-input .MuiOutlinedInput-root:hover fieldset {
  border-color: #d3b685;
}

.user-login-input .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #d3b685;
}

.user-login-input .MuiInputLabel-root {
  color: #d3b685;
}

.user-login-input .MuiInputLabel-root.Mui-focused {
  color: #d3b685;
}
