.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px; /* Adjust the width for smaller screens */
    max-width: 90%; /* Max width for larger screens */
    background-color: #333333;
    color: #d3b685;
    box-shadow: 24px;
    padding: 32px;
    border-radius: 10px;
    overflow-y: auto; /* Add scroll for overflow content */
    max-height: 90vh; /* Limit the height to be responsive */
    box-sizing: border-box; /* Ensure padding is included in the height */
  }
  
  .modal-title {
    margin-top: 10px;
    margin-bottom: 16px;
    text-align: center;
    color: #999999;
    font-size: 14px;
  }
  
  .modal-form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-input {
    margin-bottom: 16px;
  }
  
  .modal-input .MuiOutlinedInput-root {
    color: #d3b685;
  }
  
  .modal-input .MuiOutlinedInput-root fieldset {
    border-color: #d3b685;
  }
  
  .modal-input .MuiOutlinedInput-root:hover fieldset {
    border-color: #d3b685;
  }
  
  .modal-input .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #d3b685;
  }
  
  .modal-input .MuiInputLabel-root {
    color: #d3b685;
  }
  
  .modal-input .MuiInputLabel-root.Mui-focused {
    color: #d3b685;
  }
  
  .modal-button {
    margin-top: 10px !important;
    background-color: #d3b685 !important;
    color: #333333 !important;
  }
  
  .modal-button:hover {
    background-color: #c5a367 !important;
  }
  
  @media (max-width: 600px) {
    .modal-box {
      width: 100%; /* Adjust width for very small screens */
      height: auto;
    }
  }
  