.header{
    position: absolute;
    top:10px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#f3f3f3;

}

.header .menuLeft{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.header .menuRight{
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.header .buttons{
    display: flex;
    cursor: pointer;
    color:#d3b685;
    transition: all 0.3s ease-in-out;
}   

.header .buttons:hover{
    cursor: pointer;
    color:#666666;
}   

.header .tel{
    color: #d3b685;
    font-size: 14px;
    margin-left: 10px;
    font-weight: bold;
}