.selector {
  background-color: #333333;
  color: #d3b685;
  border-radius: 5px;
  padding: 2px 8px;
  font-size: 0.875rem;
  height: 30px;
  margin-right: 10px;
}

.selector .MuiSelect-outlined {
  border-color: #d3b685;
}


.selector .MuiOutlinedInput-input {
  color: #d3b685;
  padding: 4px 8px; /* Adjust padding for smaller size */
}

.selector .MuiSvgIcon-root {
  color: #d3b685;
}

.selector-list-item {
  background-color: #333333;
  color: #d3b685;
  font-size: 0.875rem;
}

.selector-list-item:hover {
  background-color: #434343;
}
