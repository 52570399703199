.container {
    min-height: 100vh;
    padding: 32px;
    color: #d3b685;
  }
  
  .paper-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .paper {
    flex: 1 1 calc(33.333% - 16px); /* Adjusts the width and margin between items */
    padding: 16px;
    background-color: #333333 !important;
    color: #d3b685 !important;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box; /* Ensures padding is included in the height calculation */
  }
  
  .icon {
    font-size: 40px !important;
    margin-bottom: 16px;
  }
  
  .title {
    font-weight: bold !important;
    margin-bottom: 32px !important;
  }
  